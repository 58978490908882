import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

export const LogoWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
export const BtnWrap = styled.div`
  position: absolute;
  bottom: ${calculateResponsiveSize(25)};
  right: ${calculateResponsiveSize(25)};
  ${mediaMax('mobile')} {
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
  }
`

export const FullScreenImageSection = styled.section`
  position: fixed;
  top: 0;
  width: 100%;
  min-width: 320px;
  height: 100dvh;
  z-index: 200;
  background: var(--beige);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0;

  video {
    object-fit: cover;
  }

  ${mediaMax('mobile')} {
  }
  transition: 1s;
  overflow: hidden;
  opacity: 1;

  &.hide {
    opacity: 0;
  }
`
export const Button = styled.button`
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(12)};
  font-weight: 700;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.32em;
  text-align: right;
  text-transform: uppercase;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--black);

  ${mediaMax('mobile')} {
    font-size: 12px;
    line-height: 12px;
  }
`
