import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'
import ArrowIcon from 'icons/arrow.svg'

export const Element = styled((props) => <Link {...props} />)`
  border: none;
  font-family: 'Davis Sans';
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;

  width: ${calculateResponsiveSize(54)};
  transition: 0.5s;
  display: flex;
  overflow: hidden;

  font-size: ${calculateResponsiveSize(12)};
  line-height: ${calculateResponsiveSize(12)};
  text-transform: uppercase;
  padding: ${calculateResponsiveSize(2)} ${calculateResponsiveSize(0)};
  cursor: pointer;
  text-decoration: none;

  color: var(--black);
  position: relative;
  background: transparent;

  &:visited {
    color: currentColor;
  }
  &:hover {
    width: ${calculateResponsiveSize(102)};
  }
  &.noAnim {
    transition: 0s;
    width: ${calculateResponsiveSize(77)};
    &:hover {
      width: ${calculateResponsiveSize(77)};
    }
  }

  ${mediaMax('mobile')} {
    width: 102px;
    padding: 2px 0;
    font-size: 12px;
    line-height: 12px;
    &:hover {
      width: 102px;
    }
    &.noAnim {
      width: 77px;
      &:hover {
        width: 77px;
      }
    }
  }
`
export const Arrow = styled.div`
  width: ${calculateResponsiveSize(35)};
  height: ${calculateResponsiveSize(9)};
  background: url(${ArrowIcon}) no-repeat;
  background-size: contain !important;
  flex: 0 0 auto;
  margin-left: ${calculateResponsiveSize(5)};
  ${mediaMax('mobile')} {
    width: 35px;
    height: 9px;
    margin-left: 5px;
  }
`
