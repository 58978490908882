import { createGlobalStyle } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'

const GlobalStyle = createGlobalStyle`
  :root {
    --beige: #F8F0E6;
    --white: #FFFFFF;
    --black: #000;
    --gray: #D7D2CB; 

  } 
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    font-family: inherit;
    box-sizing: border-box;
  }
  body {
    width: 100%;
    height: 100%;

    font-family: "Davis Sans";
    font-size: ${calculateResponsiveSize(16)};
    font-weight: 400;
    line-height: ${calculateResponsiveSize(22)};
    letter-spacing: 0.1em;
    
    color: var(--black);
    background: var(--beige);
    min-width: 320px;
    overflow-y: scroll;
    ${mediaMax('mobile')} {
      font-size: 12px;
    line-height: 18px;
    }
  }
  body.hidden {
    height: 100vh !important;
  }
  body.hidden .home-wrapper {
    height: 100vh;
    overflow: hidden;
  }

  @-webkit-keyframes mouse-over {
    0% {
      background-size: 0% 100%;
      background-position: 0% 100%;

    }
    100% {
      background-size: 100% 100%;
      background-position: 0% 100%;

    }
  }
  @-webkit-keyframes mouse-out {
    0% {
      background-size: 100% 100%;
      background-position: 100% 0%;

    }
    100% {
      background-size: 0% 100%;
      background-position: 100% 0%;

    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .fadeIn {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .portal {
    position: fixed;
    inset: 0px;
    z-index: 106;
    display: flex;
    justify-content: center;
  }
`

export default GlobalStyle
