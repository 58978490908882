import React from 'react'

import { Wrap, Logo } from './index.styled'

const Header = ({}) => {
  return (
    <>
      <Wrap>
        <Logo />
      </Wrap>
    </>
  )
}
export default Header
