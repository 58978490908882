import React from 'react'
import styled from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import { Link } from 'gatsby'
import HeaderLogo from 'icons/logo.svg'

export const Logo = styled.div`
  width: ${calculateResponsiveSize(47)};
  height: ${calculateResponsiveSize(51)};
  -webkit-mask: url(${HeaderLogo}) no-repeat;
  -webkit-mask-size: contain;
  background: #000;
  transition: 1s;
  ${mediaMax('mobile')} {
    width: 43px;
    height: 72px;
  }
`
export const Wrap = styled.div`
  position: absolute;
  z-index: 20;
  height: ${calculateResponsiveSize(158)};
  display: flex;
  align-items: center;
  left: ${calculateResponsiveSize(32)};
  &.right {
    right: ${calculateResponsiveSize(52)};
    left: auto;
    height: ${calculateResponsiveSize(28)};
    top: ${calculateResponsiveSize(65)};
  }

  ${mediaMax('mobile')} {
    display: none;
  }
`
export const Button = styled.button`
  border: none;
  background: none;
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.2em;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  padding: ${calculateResponsiveSize(8)} ${calculateResponsiveSize(17)};
  border: 1px solid var(--black);
  &.white {
    color: #fff;
    border: 1px solid #fff;
  }
  transition: 0.5s;

  ${mediaMax('mobile')} {
  }
`
