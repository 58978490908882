import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { mediaMax } from 'helpers/mediaQueries'
import FooterLogo from 'icons/logo-leaf.svg'

import instagramIcon from 'icons/instagram.svg'

export const Text = css`
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: ${calculateResponsiveSize(2)};
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  color: var(--black);
  ${mediaMax('mobile')} {
    font-size: 10px;
    line-height: 10px;
  }
`
export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(40)};
  height: ${calculateResponsiveSize(24.45)};
  background: url(${FooterLogo}) no-repeat;
  background-size: contain !important;
  margin-bottom: ${calculateResponsiveSize(25)};
  transition: 0.5s;
  &:hover {
    opacity: 0.5;
  }
  &.click {
    opacity: 0;
  }
  ${mediaMax('mobile')} {
    width: 40px;
    height: 24.5px;
  }
`
export const LinkItem = styled.a`
  ${Text}
  color: #00000080;
  letter-spacing: 0.2em;
`
export const LegalLink = styled((props) => <button {...props} />)`
  ${Text}
  letter-spacing: 0.2em;
  color: #00000080;
  border: none;
  background: none;
  cursor: pointer;
`
export const SalesAddress = styled.div`
  ${Text}
  ${mediaMax('mobile')} {
    display: none;
  }
`

export const Contact = styled.div`
  ${Text}
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(30)};
  & a {
    text-decoration: none;
    color: var(--black);
  }
  & ${SalesAddress} {
    display: none;
  }
  ${mediaMax('mobile')} {
    font-size: 10px;
    line-height: 10px;
    gap: 12px 46px;
    flex-wrap: wrap;
    justify-content: center;
    & a.phone {
      order: 3;
    }
    & ${SalesAddress} {
      display: flex;
    }
  }
`

export const Icon = styled.a`
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;

  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;

  width: ${calculateResponsiveSize(12)};
  height: ${calculateResponsiveSize(12)};
  display: block;
  cursor: pointer;
  background: var(--black);
  position: absolute;
  bottom: ${calculateResponsiveSize(26)};
  right: ${calculateResponsiveSize(60)};

  mask-image: url(${instagramIcon});
  -webkit-mask-image: url(${instagramIcon});
  ${mediaMax('mobile')} {
    width: 12px;
    height: 12px;
    position: static;
  }
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(30)};

  ${mediaMax('mobile')} {
    gap: 6px 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Wrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${calculateResponsiveSize(160)} ${calculateResponsiveSize(60)}
    ${calculateResponsiveSize(23)};
  color: var(--blue);
  gap: ${calculateResponsiveSize(11)};
  .nl {
    position: absolute;
    left: ${calculateResponsiveSize(50)};
    bottom: ${calculateResponsiveSize(20)};
    width: ${calculateResponsiveSize(88)};
  }
  ${mediaMax('mobile')} {
    padding: 49px 25px 84px;
    gap: 24px;
    .nl {
      display: none;
    }
  }
`
