import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition, TransitionGroup } from 'react-transition-group'
import { gsap, ScrollSmoother, ScrollTrigger } from 'helpers/gsap'
import Header from 'components/header'
import Footer from 'components/footer'
import MainIntro from '../ui/main-intro'
import CookiesBanner from '../ui/cookies-banner'
import ThemeProvider from '../../helpers/theme-provider'
import HeaderTransition from '../ui/header-transition'

import 'helpers/fonts.css'
import GlobalStyle from 'helpers/globalStyles'
import { LayoutWrapper, PageWrapper, ContentWrapper } from './index.styled'
import useWindowSize from '../../helpers/useWindowSize'

const LayoutContent = ({ children, location }) => {
  return (
    <TransitionGroup component={null}>
      <Transition key={location.pathname} timeout={{ enter: 1000, exit: 1000 }}>
        {(status) => (
          <div className={`page ${status}`}>
            <HeaderTransition status={status}>
              <LayoutWrapper>{children}</LayoutWrapper>
            </HeaderTransition>
          </div>
        )}
      </Transition>
    </TransitionGroup>
  )
}

const Layout = (props) => {
  const {
    children,
    location,
    pageContext: { layout },
  } = props
  const refWrapper = React.useRef(null)

  const scrollerSmoother = React.useRef(null)

  const { isMobile } = useWindowSize()

  React.useLayoutEffect(() => {
    let gsapContext = gsap.context(() => {
      scrollerSmoother.current = ScrollSmoother.create({
        content: '.home-content',
        wrapper: '.home-wrapper',
        smooth: 0.1,
        effects: false,
      })
      //pin
      gsap.utils.toArray('.pin').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          pinSpacing: false,
          pin: true,
          start: 'bottom bottom',
          anticipatePin: 1,
        })
      })
      //fadeIn
      gsap.utils.toArray('.fadeIn').forEach(function (container) {
        ScrollTrigger.create({
          trigger: container,
          start: 'top 90%',
          end: '+=1000%',
          toggleClass: {
            targets: container,
            className: 'visible',
            toggle: 'add',
          },
        })
      })
      gsap.utils.toArray('.parallax').forEach(function (container) {
        scrollerSmoother.current.effects(
          container.querySelectorAll('.content'),
          {
            speed: '0.6s',
          },
        )
      })
    }, refWrapper)

    return () => gsapContext.revert()
  }, [children, isMobile])

  return (
    <ThemeProvider location={location} scrollSmoother={scrollerSmoother}>
      <div className="home-wrapper" ref={refWrapper}>
        <div className="home-content">
          <PageWrapper>
            <GlobalStyle />
            <ContentWrapper>
              <LayoutContent location={location}>{children}</LayoutContent>
            </ContentWrapper>
          </PageWrapper>
          <Footer />
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
