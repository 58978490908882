import React, { useState, useEffect } from 'react'

import {
  FullScreenImageSection,
  LogoWrap,
  BtnWrap,
  Button,
} from './index.styled'
import video from 'static/animation_desktop.mp4'
import ReactPlayer from 'react-player'

const MainIntro = ({ setIsFirstLoad, setIsPlaying, playing = true }) => {
  const [isFinish, seIsFinish] = useState(false)

  useEffect(() => {
    document.body.classList.add('hidden')
    document.querySelector('#firstSlideLogo').classList.add('hide')
  }, [])

  useEffect(() => {
    if (isFinish) {
      // setIsPlaying(true)
      setTimeout(() => {
        document.body.classList.remove('hidden')
        setIsFirstLoad(false)
      }, 1000)
      setTimeout(() => {
        document.querySelector('#firstSlideLogo').classList.remove('hide')
      }, 2000)
    }
  }, [isFinish])

  const handleProgress = (progress) => {
    const { playedSeconds, loadedSeconds } = progress
    const remainingTime = loadedSeconds - playedSeconds

    if (remainingTime <= 3) {
      setIsPlaying(true)
    }
  }

  return (
    <FullScreenImageSection className={isFinish ? 'hide' : ''}>
      <LogoWrap>
        <ReactPlayer
          url={video}
          muted
          loop={false}
          playsinline
          height="100%"
          width="100%"
          controls={false}
          playing={playing}
          onEnded={() => {
            seIsFinish(true)
          }}
          onProgress={handleProgress}
        />
        <BtnWrap>
          <Button onClick={() => seIsFinish(true)}>skip</Button>
        </BtnWrap>
      </LogoWrap>
    </FullScreenImageSection>
  )
}

export default MainIntro
