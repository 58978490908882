import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Wrapper,
  Logo,
  LinkItem,
  Contact,
  Icon,
  Links,
  LegalLink,
  SalesAddress,
} from './index.styled'
import fhnFile from 'static/fairhousingnotice.pdf'
import sopFile from 'static/NY-StandardOperatingProcedures.pdf'
import useWindowSize from '../../helpers/useWindowSize'
import NaftaliGroupLogo from 'icons/naftali-logo.svg'

import { ThemeContext } from '../../helpers/theme-provider'

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { phone, email, address, insta, sales_address },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          phone
          gi_email
          address
          insta
          email
          pr
          sales_address
        }
      }
    }
  `)
  const { isMobile } = useWindowSize()
  const { setThemeLegal } = React.useContext(ThemeContext)

  const onLeafClick = (e) => {
    const section = document.querySelector('#video-section')
    section.classList.add('fade')

    setTimeout(() => {
      section.classList.remove('fade')
    }, 1000)
  }

  return (
    <Wrapper>
      {!isMobile && (
        <a
          href="https://www.naftaligroup.com/"
          target="_blank"
          rel="noreferrer"
          className={'nl'}
        >
          <img
            src={NaftaliGroupLogo}
            alt="Naftali Group"
            className="naftaligroup"
          />
        </a>
      )}
      <Logo to={'/'} onClick={onLeafClick} />
      <Contact>
        <div>{address}</div>
        <SalesAddress>{sales_address}</SalesAddress>
        <a href={`mailto:${email}`}>{email}</a>
        <a href={`tel:${phone}`} className={'phone'}>
          {phone}
        </a>
      </Contact>
      <SalesAddress>{sales_address}</SalesAddress>
      {isMobile && <Icon href={insta} target={'_blank'} />}
      <Links>
        <LegalLink onClick={() => setThemeLegal('legal')}>Legal</LegalLink>

        <LinkItem href={fhnFile} target={'_blank'} as="a" rel="noreferrer">
          FAIR HOUSING NOTICE
        </LinkItem>
        <LinkItem href={sopFile} target={'_blank'} as="a" rel="noreferrer">
          STANDARD OPERATING PROCEDURES
        </LinkItem>
        {!isMobile && <Icon href={insta} target={'_blank'} />}
      </Links>
    </Wrapper>
  )
}

export default Footer
