import React, { createContext, useCallback, useEffect, useState } from 'react'

export const ThemeContext = createContext()

const ThemeProvider = ({ location, scrollSmoother, children }) => {
  const [siteTheme, setSiteTheme] = useState('')
  const [siteThemeLegal, setSiteThemeLegal] = useState('')

  const [showSplash, setShowSplash] = useState(true)
  const [isProtect, setIsProtect] = useState(false)

  const setTheme = useCallback((name) => {
    setSiteTheme(name)
  }, [])
  const setThemeLegal = useCallback((name) => {
    setSiteThemeLegal(name)
  }, [])

  useEffect(() => {
    if (showSplash && location.pathname !== '/') {
      setShowSplash(false)
    }
  }, [location, showSplash, setShowSplash])

  return (
    <ThemeContext.Provider
      value={{
        siteTheme,
        setTheme,
        siteThemeLegal,
        setThemeLegal,
        showSplash,
        setShowSplash,
        isProtect,
        setIsProtect,
        scrollSmoother,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider
